@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

@font-face {
    font-family: 'PyeongChang';
    src: url('./assets/fonts/PyeongChangPeace-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PyeongChang';
    src: url('./assets/fonts/PyeongChangPeace-Light.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GanwonEduAll';
    src: url('./assets/fonts/GanwonEduAll-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

.PyeongChang {
    font-family: 'PyeongChang', sans-serif;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none

}

button, input {
    font-family: inherit;
}
